import styled from 'styled-components';

import bg from '~/assets/tv/bg-preventive-check-up.svg';

interface ISlide {
  show: boolean;
  startAnim: boolean;
  notShow?: boolean;
}

export const Container = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.show ? 1 : 0)};
  background-image: url(${bg});
  background-position: -1px -1px;
  background-size: contain;
  background-color: #3f362f;
  min-width: 980px;
  min-height: 551.25px;
`;

export const Slide1 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .photo {
      width: 421px;
      height: 471px;
      position: absolute;
      left: 509px;
      top: 80px;
      transition-duration: 0.3s;
      opacity: ${(props) => (props.show ? 1 : 0)};
    }

    .logo {
      width: 60.33px;
      height: 47.61px;
      position: absolute;
      left: 851.2px;
      top: 70.93px;
      transition-duration: 0.3s;
      opacity: ${(props) => (props.startAnim && props.hidden ? 0 : 1)};
    }

    .text {
      width: 294px;
      position: absolute;
      left: 82.27px;
      top: 412.32px;
      transition-duration: 0.3s;
      opacity: ${(props) => (props.show ? 1 : 0)};
    }
  }
`;

export const Slide2 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .text {
      width: 497px;
      position: absolute;
      left: 241.5px;
      top: 247.5px;
    }
  }
`;

export const Slide3 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .text {
      width: 636.44px;
      position: absolute;
      left: 171.78px;
      top: 219.5px;
    }
  }
`;

export const Slide4 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .photo {
      width: 421px;
      height: 402px;
      position: absolute;
      left: 509px;
      top: 149px;
      border-radius: 50px 50px 0 0;
    }

    .text {
      width: 302px;
      position: absolute;
      left: 103.54px;
      top: 177.5px;
    }

    p {
      width: 407.56px;
      position: absolute;
      left: 103.54px;
      top: 331.63px;
      font-size: 24px;
      color: #ffffff;
      transition-duration: 0.3s;
      opacity: ${(props) => (props.show ? 1 : 0)};
      font-weight: 300;
    }
  }
`;

export const Slide5 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.show ? 1 : 0)};

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .text {
      width: 543px;
      position: absolute;
      left: 219px;
      top: 79.46px;
    }

    .box {
      border-radius: 22px;
      border: 1px solid #515151;
      background: rgba(35, 35, 35, 0.33);
      backdrop-filter: blur(9.199999809265137px);
      width: 689.05px;
      padding: 32px 47px;
      position: absolute;
      left: 145.48px;
      top: 167.4px;

      > div {
        width: 251.09px;

        img {
          width: 251.09px;
          height: 205.363px;
          object-fit: cover;
          border-radius: 10px;
          background: #d9d9d9;
        }

        p {
          color: #fff;
          font-weight: 300;
          margin-top: 11px;
          text-align: center;
          width: 100%;
          margin-bottom: 0;
          font-size: 24px;
        }
      }
    }
  }
`;

export const Slide6 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .text {
      width: 534px;
      position: absolute;
      left: 223px;
      top: 220px;
    }
  }
`;
