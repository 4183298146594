import styled from 'styled-components';

import bg from '~/assets/tv/bg-preventive-check-up.svg';

interface ISlide {
  show: boolean;
  startAnim: boolean;
  notShow?: boolean;
}

export const Container = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.show ? 1 : 0)};
  background-image: url(${bg});
  background-position: -1px -1px;
  background-size: contain;
  background-color: #3f362f;
  min-width: 980px;
  min-height: 551.25px;
`;

export const Slide1 = styled.div<ISlide>`
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  position: absolute;
  top: 0;
  left: 0;

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .photo {
      width: 422px;
      height: 455.51px;
      position: absolute;
      left: 58px;
      top: 47.75px;
      border-radius: 28px;
    }

    .logo {
      width: 187.51px;
      height: 29.85px;
      position: absolute;
      left: 736.45px;
      top: 80.01px;
    }

    p {
      width: 362.93px;
      position: absolute;
      left: 561.03px;
      top: 331.85px;
      font-size: 34px;
      text-align: end;
      color: #ffffff;
      font-weight: 200;

      b {
        font-weight: 500;
      }
    }
  }
`;

export const Slide2 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .photo {
      width: 414.91px;
      height: 415.82px;
      position: absolute;
      left: 524.64px;
      top: 135.18px;
      border-radius: 28px 28px 0 0;
      transition-duration: 0.3s;
      opacity: ${(props) => (props.show ? 1 : 0)};
    }

    .logo {
      width: 60.33px;
      height: 47.61px;
      position: absolute;
      left: 848.09px;
      top: 70.93px;
      transition-duration: 0.3s;
      opacity: ${(props) => (props.notShow ? 1 : 0)};
    }

    p.first-p {
      width: 289.77px;
      position: absolute;
      left: 71.07px;
      top: 152.93px;
      font-size: 20px;
      color: #d1cfcd;
      transition-duration: 0.3s;
      opacity: ${(props) => (props.notShow ? 1 : 0)};
    }

    h1 {
      width: 362.93px;
      position: absolute;
      left: 71.07px;
      top: 302.59px;
      font-size: 34px;
      color: #ffffff;
      transition-duration: 0.3s;
      opacity: ${(props) => (props.show ? 1 : 0)};
    }

    p.last-p {
      width: 360px;
      position: absolute;
      left: 71.07px;
      top: 415.58px;
      font-size: 20px;
      color: #ffffff;
      transition-duration: 0.3s;
      opacity: ${(props) => (props.show ? 1 : 0)};

      span {
        border: 1px solid #fff;
      }
    }
  }
`;

export const Slide3 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .photo {
      width: 414.91px;
      height: 415.82px;
      position: absolute;
      left: 524.64px;
      top: 135.18px;
      border-radius: 28px 28px 0 0;
      transition-duration: 0.3s;
      opacity: ${(props) => (props.show ? 1 : 0)};
    }

    h1 {
      width: 362.93px;
      position: absolute;
      left: 71.07px;
      top: 302.59px;
      font-size: 34px;
      color: #ffffff;
      transition-duration: 0.3s;
      opacity: ${(props) => (props.show ? 1 : 0)};
    }

    p.last-p {
      width: 360px;
      position: absolute;
      left: 71.07px;
      top: 415.58px;
      font-size: 20px;
      color: #ffffff;
      transition-duration: 0.3s;
      opacity: ${(props) => (props.show ? 1 : 0)};

      span {
        border: 1px solid #fff;
      }
    }
  }
`;

export const Slide4 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .photo {
      width: 414.91px;
      height: 415.82px;
      position: absolute;
      left: 524.64px;
      top: 135.18px;
      border-radius: 28px 28px 0 0;
      transition-duration: 0.3s;
      opacity: ${(props) => (props.show ? 1 : 0)};
    }

    h1 {
      width: 362.93px;
      position: absolute;
      left: 71.07px;
      top: 302.59px;
      font-size: 34px;
      color: #ffffff;
      transition-duration: 0.3s;
      opacity: ${(props) => (props.show ? 1 : 0)};
    }

    p.last-p {
      width: 360px;
      position: absolute;
      left: 71.07px;
      top: 415.58px;
      font-size: 20px;
      color: #ffffff;
      transition-duration: 0.3s;
      opacity: ${(props) => (props.show ? 1 : 0)};

      span {
        border: 1px solid #fff;
      }
    }
  }
`;

export const Slide5 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.show ? 1 : 0)};

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .logo {
      width: 215.53px;
      height: 34.32px;
      position: absolute;
      left: 382.24px;
      top: 437.62px;
    }

    .text {
      width: 642.06px;
      position: absolute;
      left: 168.97px;
      top: 230.5px;
    }
  }
`;
