import styled from 'styled-components';

import bg from '~/assets/tv/bg-plenum.svg';

interface ISlide {
  show: boolean;
  startAnim: boolean;
  notShow?: boolean;
}

export const Container = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.show ? 1 : 0)};
  background-image: url(${bg});
  background-position: -1px -1px;
  background-size: contain;
  background-color: #c8c8c8;
  min-width: 980px;
  min-height: 551.25px;
`;

export const Slide1 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .plenum {
      width: 132.25px;
      height: 35px;
      position: absolute;
      left: 82.27px;
      top: 76.95px;
      border-radius: 28px;
      transition-duration: 0.3s;
      opacity: ${(props) => (props.notShow ? 1 : 0)};
    }

    .implant {
      width: 235.41px;
      height: 451.13px;
      position: absolute;
      left: 604.77px;
      top: 99.87px;
      border-radius: 28px;
      transition-duration: 0.3s;
      opacity: ${(props) => (props.show ? 1 : 0)};
    }

    .logo {
      width: 60.33px;
      height: 47.61px;
      position: absolute;
      left: 851.2px;
      top: 70.93px;
      transition-duration: 0.3s;
      opacity: ${(props) => (props.show ? 1 : 0)};
    }

    p {
      width: 429.96px;
      position: absolute;
      left: 82.27px;
      top: 291.32px;
      font-size: 40px;
      color: #202020;
      font-weight: 300;
      transition-duration: 0.3s;
      opacity: ${(props) => (props.show ? 1 : 0)};

      b {
        font-weight: 500;
      }
    }
  }
`;

export const Slide2 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    p {
      width: 429.96px;
      position: absolute;
      left: 82.27px;
      top: 291.32px;
      font-size: 40px;
      color: #202020;
      font-weight: 300;
    }

    > div {
      video {
        position: absolute;
        left: 503.08px;
        top: 47.53px;
        width: 422px;
        height: 455.51px;
        border-radius: 28px;
      }
    }
  }
`;
