import styled from 'styled-components';

interface ISlide {
  show: boolean;
  startAnim: boolean;
}

interface IStepBg {
  step: number;
  color: string;
  position: number;
}

interface IStep {
  active: boolean;
}

export const Container = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.show ? 1 : 0)};

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .bg-step {
      position: absolute;
      top: 0;
      left: 0;
      width: 980px;
      height: 551.25px;
      opacity: 0;
      transition-duration: 0.3s;
    }

    .bg-step.show {
      opacity: 1;
    }

    .steps {
      position: absolute;
      top: 0;
      right: ${(props) => (props.startAnim ? 0 : -600)}px;
      border-radius: 30px 0px 0px 30px;
      background: #fff;
      backdrop-filter: blur(400px);
      width: 264.02px;
      height: 551.25px;
      transition-duration: 0.6s;

      .logo {
        width: 112.93px;
        height: 39.26px;
        position: absolute;
        left: 50%;
        top: 56.14px;
        transform: translateX(-50%);
      }

      > div {
        position: absolute;
        left: 50%;
        top: 157.88px;
        transform: translateX(-50%);
        width: 176.33px;

        .line {
          width: 1px;
          height: 300px;
          background-color: #8d8d8d;
          position: absolute;
          left: 17px;
          top: 55%;
          transform: translateY(-50%);
          z-index: -1;
        }
      }
    }
  }
`;

export const StepBg = styled.div<IStepBg>`
  width: 33.88px;
  height: 33.88px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  position: absolute;
  transition-duration: 0.6s;
  top: ${(props) => props.position}px;
`;

export const Step = styled.div<IStep>`
  display: flex;
  align-items: center;

  .icon {
    width: 33.88px;
    height: 33.88px;
    border: 1px solid ${(props) => (props.active ? 'transparent' : '#aeaeae')};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;

    svg {
      width: 24px;
      height: 24px;

      path {
        transition-delay: 0.1s;
        transition-duration: 0.3s;
        fill: ${(props) => (props.active ? '#fff' : '')};
      }
    }

    ::after {
      content: '';
      width: 65px;
      height: 60px;
      background-color: #fff;
      position: absolute;
      z-index: -1;
    }
  }

  .content {
    width: calc(100% - 50.88px);
    font-size: 16px;
    color: #202020;
    margin-left: 17px;
    transition-duration: 0.3s;
    font-weight: ${(props) => (props.active ? 600 : 400)};
  }

  + div {
    margin-top: 68px;
  }
`;
