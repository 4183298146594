import React from 'react';

const tv: React.FC = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_853_182"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="38"
        height="38"
      >
        <rect width="38" height="38" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_853_182)">
        <path
          d="M24.121 10.7419H32.512C33.334 10.7419 34 11.3887 34 12.1955V32.5464C34 33.3488 33.3175 34 32.512 34H5.48803C5.29172 33.9996 5.09742 33.9617 4.91628 33.8883C4.73514 33.815 4.57073 33.7077 4.43248 33.5727C4.29423 33.4376 4.18486 33.2774 4.11064 33.1013C4.03643 32.9252 3.99884 32.7366 4.00003 32.5464V12.1955C4.00003 11.3931 4.68253 10.7419 5.48803 10.7419H13.879L10.075 7.05544L12.196 5L18.121 10.7419H19.879L25.804 5L27.925 7.05544L24.121 10.7419ZM7.00002 13.6491V31.0927H31V13.6491H7.00002Z"
          fill="#7F3CF4"
        />
      </g>
    </svg>
  );
};

export default tv;
