import React from 'react';

const tv: React.FC = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_853_183"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="38"
        height="38"
      >
        <rect width="38" height="38" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_853_183)">
        <path
          d="M26.6478 1.64673C26.5759 1.68594 24.8638 3.3719 22.8381 5.39113C18.8976 9.32504 18.976 9.23356 19.0741 9.79554C19.1067 9.99812 19.3224 10.2464 20.5313 11.4619L21.9428 12.8799L13.2777 21.5581C7.40302 27.4328 4.54735 30.3342 4.42319 30.5498C3.65862 31.8895 3.95268 33.5035 5.1224 34.438C5.67785 34.8758 6.06994 35 6.94559 35C7.67748 35 7.71016 34.9935 8.21986 34.7517L8.74264 34.5034L21.4658 21.7802C34.9142 8.32523 34.3719 8.89375 34.6267 8.05077C34.7443 7.65215 34.7443 6.84184 34.6202 6.42362C34.3392 5.47609 33.6465 4.77033 32.6859 4.45013C32.333 4.32597 32.1697 4.3129 31.6469 4.33904C31.3005 4.35865 30.9215 4.41746 30.7908 4.46974L30.5621 4.57429L29.1571 3.16933C28.386 2.39169 27.6803 1.71861 27.5823 1.66634C27.3732 1.56178 26.8373 1.54871 26.6478 1.64673ZM26.1773 8.64543L23.3804 11.4423L22.4525 10.5144L21.518 9.5799L21.9689 9.129L22.4264 8.67157L22.7204 8.94603C23.0537 9.25316 23.2301 9.31851 23.6353 9.27276C24.0797 9.22049 24.3607 8.9591 24.4652 8.50167C24.5436 8.16839 24.426 7.81552 24.1319 7.49532L23.8771 7.22086L24.3149 6.78303L24.7593 6.3452L24.9814 6.58045C25.3866 7.00521 25.7852 7.0967 26.2557 6.86798C26.7132 6.6458 26.9288 6.07728 26.7393 5.58064C26.7066 5.48262 26.5629 5.28658 26.4322 5.14935L26.1969 4.90756L26.6478 4.45013L27.1052 3.9927L28.0332 4.92063L28.9676 5.8551L26.1773 8.64543ZM32.2611 6.46936C32.699 6.75689 32.8362 7.37769 32.5552 7.75671C32.3396 8.0377 7.94541 32.458 7.57946 32.7521C7.31807 32.9612 7.21352 33.0069 6.97173 33.0069C6.80836 33.0069 6.59272 32.9612 6.48163 32.9089C6.08301 32.6998 5.9131 32.1182 6.12222 31.713C6.23331 31.517 31.2156 6.52818 31.4182 6.41709C31.6273 6.306 32.052 6.33214 32.2611 6.46936Z"
          fill="#039AF4"
        />
      </g>
    </svg>
  );
};

export default tv;
