import React from 'react';

const tv: React.FC = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_853_184"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="38"
        height="38"
      >
        <rect width="38" height="38" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_853_184)">
        <path
          d="M34 11.1489C34 9.23877 33.2562 7.44208 31.9056 6.09456C30.5551 4.74704 28.7601 4 26.8517 4C24.9622 4 23.1846 4.72971 21.8404 6.05516C21.7978 6.09456 9.2852 18.6068 9.2852 18.6068C8.69739 19.1946 8.15528 20.041 7.76131 20.9929C7.45401 21.7384 4.6426 30.2333 4.08001 31.9291C3.89248 32.4933 4.04061 33.1174 4.46138 33.5382C4.75922 33.8361 5.16265 34 5.57554 34C5.74101 34 5.90805 33.9716 6.07037 33.9196C7.76603 33.3601 16.2585 30.5453 17.0039 30.238C17.9542 29.8471 18.8005 29.305 19.3914 28.714L22.7906 25.316L22.9104 25.3318C23.919 25.3318 24.9276 24.9472 25.6982 24.1781L32.7897 17.0859C33.8865 15.9905 34.1875 14.4082 33.7163 13.0323C33.885 12.424 34 11.7983 34 11.1489ZM15.3036 27.5067C14.6637 27.7321 13.4991 28.1261 12.0919 28.5957L9.40654 25.9102C9.88089 24.5012 10.2749 23.3349 10.4955 22.6982L15.3036 27.5067ZM8.8471 27.5808L10.4214 29.1552L8.06073 29.9417L8.8471 27.5808ZM20.9784 22.6745L17.1647 26.4886L16.7944 26.7723L11.2314 21.2104L11.5151 20.8416L22.7528 9.60284L27.0566 13.907L21.2415 19.7226C20.4331 20.5296 20.3764 21.7541 20.9784 22.6745ZM30.5598 14.8589L23.4683 21.9511C23.3138 22.1072 23.1121 22.1812 22.9104 22.1812C22.7087 22.1812 22.507 22.104 22.3525 21.9511C22.0452 21.6454 22.0452 21.1442 22.3525 20.8369L29.4441 13.7447C29.7498 13.4389 30.2509 13.4389 30.5582 13.7447C30.8671 14.052 30.8687 14.55 30.5598 14.8589ZM30.7284 12.0788C29.9105 11.814 28.9823 11.9811 28.3315 12.6304L28.1708 12.7912L23.867 8.487L23.9899 8.36407C25.5406 6.81954 28.1692 6.81797 29.6757 8.32782C30.4306 9.08274 30.8466 10.0867 30.8466 11.1537C30.8482 11.4673 30.7994 11.7746 30.7284 12.0788Z"
          fill="#FF8500"
        />
      </g>
    </svg>
  );
};

export default tv;
