import styled from 'styled-components';

interface ISlide {
  show: boolean;
  startAnim: boolean;
  notShow?: boolean;
}

export const Container = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.show ? 1 : 0)};
  background-color: #f3f1eb;
  min-width: 980px;
  min-height: 551.25px;
`;

export const Slide1 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    img {
      position: absolute;
      left: ${(props) => (props.startAnim ? 0 : -513.62)}px;
      top: 0;
      width: 513.62px;
      height: 551px;
      transition-duration: 0.3s;
    }

    p {
      margin-bottom: 0;
    }

    .content {
      position: absolute;
      left: 551.28px;
      top: 205px;
      width: 353.04px;
      height: 141px;
      transition-duration: 0.3s;
      transform: scale(${(props) => (props.startAnim ? 1 : 0)});
      display: flex;
      align-items: center;

      > p {
        font-size: 100px;
        color: #442300;
        font-weight: 600;
        margin-right: 24px;
      }

      > div {
        p:nth-child(1) {
          font-size: 50px;
          color: #984f0e;
          font-weight: 600;
          margin-bottom: -18px;
        }
        p:nth-child(2) {
          font-size: 50px;
          color: #984f0e;
          font-weight: 100;
        }
      }
    }
  }
`;

export const Slide2 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    p.position {
      background-color: #442300;
      margin: 0;
      position: absolute;
      left: 82.57px;
      top: 198.26px;
      width: 59.41px;
      height: 59.41px;
      color: #fff;
      font-weight: 600;
      font-size: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
    }

    p.content {
      position: absolute;
      left: 82.57px;
      top: 285.98px;
      font-size: 40px;
      color: #984f0e;
      transition-duration: 0.3s;
      opacity: ${(props) => (props.startAnim ? 1 : 0)};
      font-weight: 300;

      b {
        font-weight: 500;
      }
    }

    img {
      position: absolute;
      left: 503.43px;
      top: 56.92px;
      width: 407.51px;
      height: 437.17px;
      border-radius: 30px;
    }
  }
`;
