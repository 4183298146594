import React from 'react';
import { BsFillEnvelopeFill, BsFillTelephoneFill } from 'react-icons/bs';
import { TbMapPinFilled } from 'react-icons/tb';

import { ImWhatsapp } from 'react-icons/im';
import { Container } from './styles';

import reception from '~/assets/defaults/reception.png';

const Contact: React.FC = () => {
  return (
    <Container>
      <div className="container py-5">
        <div className="row">
          <div className="col-12">
            <div className="box px-3 pt-0 pb-4 p-md-5">
              <div className="row px-lg-5 align-items-center justify-content-between">
                <div className="col-lg-5 py-5">
                  <div className="w-100">
                    <h1 className="h2 h1-lg fw-semibold mb-4">
                      Fale <br />
                      conosco
                    </h1>
                    <p className="text-secondary">
                      Entre em contato conosco para transformar sua visão em
                      realidade. Estamos prontos para criar experiências
                      incríveis juntos.
                    </p>
                  </div>
                  <div className="mt-5">
                    <a
                      href="mailto:contato@coiprime.com.br"
                      className="d-block"
                    >
                      <div className="d-flex align-items-center">
                        <BsFillEnvelopeFill
                          size={24}
                          color="#BE7E38"
                          className="me-2"
                        />
                        <span className="fw-normal">
                          contato@coiprime.com.br
                        </span>
                      </div>
                    </a>
                    <div className="phone-numbers my-5">
                      <div className="d-flex align-items-center">
                        <BsFillTelephoneFill
                          size={24}
                          color="#BE7E38"
                          className="me-2"
                        />
                        <p className="mb-0 fw-normal">
                          <a href="tel:+551141981987" className="no-hover">
                            <span>(11) 4198-1987</span>
                          </a>
                          <span>&nbsp;|&nbsp;</span>
                          <a href="tel:+551155312353" className="no-hover">
                            <span>(11) 5531-2353</span>
                          </a>
                        </p>
                      </div>
                    </div>
                    <a
                      href="https://api.whatsapp.com/send?phone=5511967746140&text=Olá gostaria de agendar uma consulta"
                      rel="noopener noreferrer"
                      target="_blank"
                      className="d-block mb-4"
                      title="Whatsapp: (11) 96774-6140"
                    >
                      <div className="d-flex align-items-center">
                        <ImWhatsapp
                          size={24}
                          color="#BE7E38"
                          className="me-2"
                        />
                        <span className="fw-normal">(11) 96774-6140</span>
                      </div>
                    </a>
                    <a
                      href="https://maps.app.goo.gl/LPv5rTrzvAVKhsVSA"
                      rel="noopener noreferrer"
                      target="_blank"
                      className="d-block pt-2"
                    >
                      <div className="d-flex align-items-center">
                        <TbMapPinFilled
                          size={24}
                          color="#BE7E38"
                          className="me-2"
                        />
                        <span className="fw-normal">
                          Av. Trindade, 165 – Bethaville&nbsp;I,{' '}
                          <br className="d-block d-lg-none d-xl-block" />
                          Barueri – SP, 06404-326
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 mt-4 mt-lg-0">
                  <img
                    src={reception}
                    alt="A recepçao vazia dando destaque na logo COI Prime Odontologia"
                    className="w-100 mb-4 mb-lg-0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Contact;
