import React, { useCallback, useEffect, useState } from 'react';

import { Container, Step, StepBg } from './styles';

import coikidsLogo from '~/assets/tv/logo-kids-black.svg';
import roomKids2 from '~/assets/tv/kids-room-2.png';
import roomKids3 from '~/assets/tv/kids-room-3.png';
import roomKids4 from '~/assets/tv/kids-room-4.png';
import roomKids5 from '~/assets/tv/kids-room-5.png';
import Pen from '~/assets/tv/Pen';
import Toothbrush from '~/assets/tv/Toothbrush';
import Tv from '~/assets/tv/Tv';
import DetistChair from '~/assets/tv/DetistChair';

interface ISlideFantasticWorldTimeline {
  show: boolean;
  startAnim: boolean;
}

const SlideFantasticWorldTimeline: React.FC<ISlideFantasticWorldTimeline> = ({
  show,
  startAnim,
}) => {
  const [step, setStep] = useState(0);
  const [stepColor, setStepColor] = useState('#FF8500');
  const [position, setPosition] = useState(7);

  const changeSlide = useCallback((stepSelected) => {
    let color = '#FF8500';
    let positionStep = 7;
    switch (stepSelected) {
      case 2:
        color = '#039AF4';
        positionStep = 116;
        break;
      case 3:
        color = '#7F3CF4';
        positionStep = 218;
        break;
      case 4:
        color = '#079172';
        positionStep = 320;
        break;

      default:
        color = '#FF8500';
        positionStep = 7;
        break;
    }

    setTimeout(() => {
      setStep(stepSelected);
      setStepColor(color);
      setPosition(positionStep);

      if (stepSelected + 1 <= 4) {
        changeSlide(stepSelected + 1);
      } else {
        setTimeout(() => {
          setStep(0);
        }, 5000);
      }
    }, 5000);
  }, []);

  useEffect(() => {
    if (startAnim) {
      setStep(1);
      setStepColor('#FF8500');
      setPosition(7);
      setTimeout(() => {
        changeSlide(2);
      }, 300);
    }
  }, [changeSlide, startAnim]);

  return (
    <Container show={show} startAnim={startAnim}>
      <div className="slide-container">
        <img
          src={roomKids2}
          alt="roomKids2"
          className={`bg-step ${step === 1 ? 'show' : 'hide'}`}
        />
        <img
          src={roomKids3}
          alt="roomKids3"
          className={`bg-step ${step === 2 ? 'show' : 'hide'}`}
        />
        <img
          src={roomKids4}
          alt="roomKids4"
          className={`bg-step ${step === 3 ? 'show' : 'hide'}`}
        />
        <img
          src={roomKids5}
          alt="roomKids5"
          className={`bg-step ${step === 4 ? 'show' : 'hide'}`}
        />
        <div className="steps">
          <img src={coikidsLogo} alt="coikidsLogo" className="logo" />
          <div>
            <div className="line" />
            <StepBg step={step} color={stepColor} position={position} />
            <Step active={step === 1}>
              <div className="icon">
                <Pen />
              </div>
              <p className="content mb-0">Cantinho do desenho</p>
            </Step>
            <Step active={step === 2}>
              <div className="icon">
                <Toothbrush />
              </div>
              <p className="content mb-0">Amigo&nbsp;escovão</p>
            </Step>
            <Step active={step === 3}>
              <div className="icon">
                <Tv />
              </div>
              <p className="content mb-0">TV</p>
            </Step>
            <Step active={step === 4}>
              <div className="icon">
                <DetistChair />
              </div>
              <p className="content mb-0">Sala temática</p>
            </Step>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SlideFantasticWorldTimeline;
