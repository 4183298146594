/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Container, Slide1, Slide2 } from './styles';

import plenumLogo from '~/assets/tv/plenum-logo.svg';
import shortLogo from '~/assets/tv/gray-short-logo.svg';
import implant from '~/assets/tv/implant.png';
import plenumVideo from '~/assets/tv/plenum.mp4';

interface IPlenum {
  show: boolean;
  startAnim: boolean;
}

interface ISlide {
  slide: string;
  slideTime: number;
  beforeTime?: number;
  afterTime?: number;
  bgSlide?: string;
}

const Plenum: React.FC<IPlenum> = ({ show, startAnim }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [slideSelected, setSlideSelected] = useState({} as ISlide);
  const [showBefore, setShowBefore] = useState('');
  const [animSlideSelected, setAnimSlideSelected] = useState('');
  const [endAnimSlideSelected, setEndAnimSlideSelected] = useState('');

  const slides = useMemo<ISlide[]>(
    () => [
      {
        slide: 'Slide1',
        slideTime: 10000,
      },
      { slide: 'Slide2', slideTime: 51000 },
    ],
    []
  );

  const changeSlide = useCallback(
    (index) => {
      const { slide, slideTime } = slides[index];

      if (slides[index + 1] && slides[index + 1].beforeTime) {
        const nextSlide = slides[index + 1];
        if (nextSlide.beforeTime)
          setTimeout(() => {
            setShowBefore(nextSlide.slide);
            setAnimSlideSelected(nextSlide.slide);
          }, slideTime - nextSlide.beforeTime);
      }

      if (slides[index - 1] && slides[index - 1].afterTime) {
        const prevSlide = slides[index - 1];
        if (prevSlide.afterTime) {
          setEndAnimSlideSelected(prevSlide.slide);
          setSlideSelected(slides[index]);
          setTimeout(() => {
            setShowBefore('');
            setEndAnimSlideSelected('');
          }, prevSlide.afterTime);
        }
      } else {
        setSlideSelected(slides[index]);
      }

      setTimeout(() => {
        setAnimSlideSelected(slide);
        if (index + 1 < slides.length) {
          setTimeout(() => {
            changeSlide(index + 1);
          }, slideTime);
        }
      }, 100);
    },
    [slides]
  );

  useEffect(() => {
    if (slideSelected.slide === 'Slide2') {
      videoRef.current?.play();
    }
  }, [slideSelected.slide]);

  useEffect(() => {
    if (show) {
      changeSlide(0);
    }
  }, [changeSlide, show]);

  return (
    <Container show={show} startAnim={startAnim}>
      <Slide1
        show={slideSelected.slide === 'Slide1'}
        startAnim={slideSelected.slide === 'Slide1'}
        notShow={slideSelected.slide !== 'Slide1'}
      >
        <div className="slide-container">
          <img src={plenumLogo} alt="plenum" className="plenum" />
          <img src={shortLogo} alt="fullLogo" className="logo" />
          <p>
            Trabalhamos com os <b>melhores implantes</b> do mercado
          </p>
          <img src={implant} alt="implant" className="implant" />
        </div>
      </Slide1>
      <Slide2
        show={slideSelected.slide === 'Slide2'}
        startAnim={slideSelected.slide === 'Slide2'}
      >
        <div className="slide-container">
          <p>Estrutura dos implantes Plenum</p>
          <div>
            <video ref={videoRef} controls={false} playsInline muted>
              <source src={plenumVideo} type="video/mp4" />
              <track default kind="captions" srcLang="en" />
              Sorry, your browser doesn't support embedded videos.
            </video>
          </div>
        </div>
      </Slide2>
    </Container>
  );
};

export default Plenum;
