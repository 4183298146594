/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Container, Slide1, Slide2 } from './styles';

import benefitsBotox from '~/assets/tv/benefits-botox.png';
import rested from '~/assets/tv/rested.png';
import expression from '~/assets/tv/expression.png';
import softens from '~/assets/tv/softens.png';
import elevate from '~/assets/tv/elevate.png';

interface IBotox {
  show: boolean;
  startAnim: boolean;
}

interface ISlide {
  slide: string;
  slideTime: number;
  beforeTime?: number;
  afterTime?: number;
  bgSlide?: string;
}

const Botox: React.FC<IBotox> = ({ show, startAnim }) => {
  const [slideSelected, setSlideSelected] = useState({} as ISlide);
  const [showBefore, setShowBefore] = useState('');
  const [animSlideSelected, setAnimSlideSelected] = useState('');
  const [endAnimSlideSelected, setEndAnimSlideSelected] = useState('');

  const slides = useMemo<ISlide[]>(
    () => [
      {
        slide: 'Slide1',
        slideTime: 5000,
      },
      { slide: 'Slide2', slideTime: 5000 },
      { slide: 'Slide3', slideTime: 5000 },
      { slide: 'Slide4', slideTime: 5000 },
      { slide: 'Slide5', slideTime: 5000 },
    ],
    []
  );

  const changeSlide = useCallback(
    (index) => {
      const { slide, slideTime } = slides[index];

      if (slides[index + 1] && slides[index + 1].beforeTime) {
        const nextSlide = slides[index + 1];
        if (nextSlide.beforeTime)
          setTimeout(() => {
            setShowBefore(nextSlide.slide);
            setAnimSlideSelected(nextSlide.slide);
          }, slideTime - nextSlide.beforeTime);
      }

      if (slides[index - 1] && slides[index - 1].afterTime) {
        const prevSlide = slides[index - 1];
        if (prevSlide.afterTime) {
          setEndAnimSlideSelected(prevSlide.slide);
          setSlideSelected(slides[index]);
          setTimeout(() => {
            setShowBefore('');
            setEndAnimSlideSelected('');
          }, prevSlide.afterTime);
        }
      } else {
        setSlideSelected(slides[index]);
      }

      setTimeout(() => {
        setAnimSlideSelected(slide);
        if (index + 1 < slides.length) {
          setTimeout(() => {
            changeSlide(index + 1);
          }, slideTime);
        }
      }, 100);
    },
    [slides]
  );

  useEffect(() => {
    if (show) {
      changeSlide(0);
    }
  }, [changeSlide, show]);

  return (
    <Container show={show} startAnim={startAnim}>
      <Slide1
        show={slideSelected.slide === 'Slide1'}
        startAnim={slideSelected.slide === 'Slide1'}
      >
        <div className="slide-container">
          <img src={benefitsBotox} alt="4 Beneficíos do Botox®" />
          <div className="content">
            <p>4</p>
            <div>
              <p>Beneficíos</p>
              <p>do Botox®</p>
            </div>
          </div>
        </div>
      </Slide1>
      <Slide2
        show={slideSelected.slide === 'Slide2'}
        startAnim={slideSelected.slide === 'Slide2'}
      >
        <div className="slide-container">
          <p className="position">1º</p>
          <p className="content">
            Deixa o rosto
            <br /> com{' '}
            <b>
              aparência
              <br /> descansada
            </b>
          </p>
          <img src={rested} alt="rested" />
        </div>
      </Slide2>
      <Slide2
        show={slideSelected.slide === 'Slide3'}
        startAnim={slideSelected.slide === 'Slide3'}
      >
        <div className="slide-container">
          <p className="position">2º</p>
          <p className="content">
            Previne{' '}
            <b>
              linhas <br />
              de expressão
            </b>{' '}
            <br />
            (Rugas)
          </p>
          <img src={expression} alt="expression" />
        </div>
      </Slide2>
      <Slide2
        show={slideSelected.slide === 'Slide4'}
        startAnim={slideSelected.slide === 'Slide4'}
      >
        <div className="slide-container">
          <p className="position">3º</p>
          <p className="content">
            <b>Suaviza</b> <br />
            as linhas que <br />
            você já tem
          </p>
          <img src={softens} alt="softens" />
        </div>
      </Slide2>
      <Slide2
        show={slideSelected.slide === 'Slide5'}
        startAnim={slideSelected.slide === 'Slide5'}
      >
        <div className="slide-container">
          <p className="position">4º</p>
          <p className="content">
            <b>Eleva</b> <br />a sua <br />
            autoestima
          </p>
          <img src={elevate} alt="elevate" />
        </div>
      </Slide2>
    </Container>
  );
};

export default Botox;
