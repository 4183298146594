import React, { useCallback, useEffect, useState } from 'react';

import {
  Container,
  Slide1,
  Slide2,
  Slide3,
  Slide4,
  Slide5,
  Slide6,
} from './styles';

import shortLogo from '~/assets/tv/white-short-logo.svg';
import man from '~/assets/tv/man-coi-experience.png';
import text1 from '~/assets/tv/coi-experience-text.png';
import text2 from '~/assets/tv/coi-experience-text-2.png';
import text3 from '~/assets/tv/coi-experience-text-3.png';
import text4 from '~/assets/tv/coi-experience-text-4.png';
import text5 from '~/assets/tv/coi-experience-text-5.png';
import text6 from '~/assets/tv/coi-experience-text-6.png';
import doctors from '~/assets/tv/doctors.png';
import before from '~/assets/tv/before.png';
import after from '~/assets/tv/after.png';

interface ICoiExperience {
  show: boolean;
  startAnim: boolean;
}

const CoiExperience: React.FC<ICoiExperience> = ({ show, startAnim }) => {
  const [step, setStep] = useState(0);

  const changeSlide = useCallback((stepSelected) => {
    setTimeout(() => {
      setStep(stepSelected);
      if (stepSelected + 1 <= 6) {
        changeSlide(stepSelected + 1);
      } else {
        setTimeout(() => {
          setStep(0);
        }, 5000);
      }
    }, 5000);
  }, []);

  useEffect(() => {
    if (startAnim) {
      setStep(1);
      setTimeout(() => {
        changeSlide(2);
      }, 300);
    }
  }, [changeSlide, startAnim]);

  useEffect(() => {
    if (startAnim) {
      setStep(1);
      setTimeout(() => {
        changeSlide(2);
      }, 300);
    }
  }, [changeSlide, startAnim]);

  return (
    <Container show={show} startAnim={startAnim}>
      <Slide1 show={step === 1} startAnim={step === 1} notShow={step !== 0}>
        <div className="slide-container">
          <img src={shortLogo} alt="short-logo" className="logo" />
          <img src={text1} alt="COI Experience" className="text" />
          <img src={man} alt="man" className="photo" />
        </div>
      </Slide1>
      <Slide2 show={step === 2} startAnim={step === 2}>
        <div className="slide-container">
          <img src={text2} alt="COI Experience" className="text" />
        </div>
      </Slide2>
      <Slide3 show={step === 3} startAnim={step === 3}>
        <div className="slide-container">
          <img src={text3} alt="COI Experience" className="text" />
        </div>
      </Slide3>
      <Slide4 show={step === 4} startAnim={step === 4}>
        <div className="slide-container">
          <img src={text4} alt="COI Experience" className="text" />
          <p>Diversos profissionais no dia disponiveis para o seu tratamento</p>
          <img src={doctors} alt="doctors" className="photo" />
        </div>
      </Slide4>
      <Slide5 show={step === 5} startAnim={step === 5}>
        <div className="slide-container">
          <img src={text5} alt="COI Experience" className="text" />
          <div className="d-flex justify-content-between box">
            <div>
              <img src={before} alt="before" />
              <p>Antes</p>
            </div>
            <div>
              <img src={after} alt="after" />
              <p>Depois</p>
            </div>
          </div>
        </div>
      </Slide5>
      <Slide6 show={step === 6} startAnim={step === 6}>
        <div className="slide-container">
          <img src={text6} alt="COI Experience" className="text" />
        </div>
      </Slide6>
    </Container>
  );
};

export default CoiExperience;
