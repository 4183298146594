import styled, { css } from 'styled-components';

interface ISlide {
  show: boolean;
  startAnim: boolean;
  step: number;
}

export const Container = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition-duration: 0.3s;

  .slide-container {
    width: 1920px;
    height: 1080px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;

    .top-detail {
      position: absolute;
      width: 100%;
      height: 158.75px;
      top: ${(props) => (props.startAnim ? 0 : -250)}px;
      left: 0;
      transition-duration: 0.6s;
    }

    .logo-group {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%);
      transition-duration: 0.6s;

      .logo {
        width: 807px;
        height: 138px;
        display: flex;
        align-items: center;
        position: relative;
        transition-duration: 0.6s;

        ::before {
          content: '';
          width: ${(props) => (props.startAnim ? 0 : 807)}px;
          height: 200px;
          background-color: #fff;
          position: absolute;
          top: 0;
          right: 0;
          z-index: 1;
          transition-duration: 0.6s;
          align-self: flex-end;
        }

        .coi-kids-outline {
          width: 801px;
          height: 133px;
          position: absolute;
          transition-duration: 0.3s;
        }

        .coi-fill {
          width: 324.65px;
          height: 133px;
          position: absolute;
          left: 10px;
          top: 9px;
          transition-delay: 0.2s;
          transition-duration: 0.6s;
          opacity: ${(props) => (props.startAnim ? 1 : 0)};
          transition-duration: 0.3s;
        }

        .kids-fill {
          width: 413.97px;
          height: 133px;
          position: absolute;
          left: 393.03px;
          top: 5px;
          transition-delay: 0.4s;
          transition-duration: 0.6s;
          opacity: ${(props) => (props.startAnim ? 1 : 0)};
          transition-duration: 0.3s;
        }
      }

      .invisalign-group {
        display: flex;
        align-items: center;
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        transition-duration: 0.2s;

        .plus {
          width: 68.42px;
          height: 68.42px;
          position: absolute;
          left: 759.82px;
          top: 39px;
        }

        .invisalign {
          width: 568px;
          height: 142px;
          position: absolute;
          left: 900.82px;
          top: 0px;
        }

        ::before {
          content: '';
          width: 807px;
          height: 200px;
          background-color: #fff;
          position: absolute;
          top: 0;
          right: -1500px;
          z-index: 1;
          align-self: flex-end;
          opacity: 0;
          transition: width 0.9s;
        }
      }
    }

    .kids {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      img {
        opacity: 0;
        transition-duration: 0.3s;

        + img {
          margin-left: 35px;
        }
      }
    }

    .content {
      width: 478px;
      font-size: 40px;
      text-align: center;
      color: #202020;
      position: absolute;
      left: 721px;
      top: 836px;
      scale: 0;
      transition-duration: 0.3s;
    }

    .bottom-detail {
      position: absolute;
      width: 100%;
      height: 153px;
      bottom: ${(props) => (props.startAnim ? 0 : -250)}px;
      left: 0;
      transition-duration: 0.6s;
    }

    ${(props) => {
      if (props.step >= 2) {
        return css`
          .logo-group {
            left: ${props.step === 3 ? 30 : 34}%;
            top: ${props.step === 3 ? 89 : 45}%;
            scale: ${props.step === 3 ? 0.2 : 1};

            .logo {
              .coi-kids-outline {
                width: 681.56px;
                height: 114.58px;
              }

              .coi-fill {
                width: 275.55px;
                height: 112.89px;
                left: 0;
                top: 12.09px;
              }

              .kids-fill {
                width: 351.37px;
                height: 112.89px;
                left: 328.5px;
                top: 12.09px;
              }
            }

            .invisalign-group {
              opacity: 1;

              ::before {
                width: 0;
                opacity: 1;
              }
            }
          }

          .bottom-detail {
            bottom: ${props.step === 3 ? -250 : 0}px;
          }
        `;
      }

      return css``;
    }}

    ${(props) => {
      if (props.step === 3) {
        return css`
          .kids {
            img {
              opacity: 1;

              :nth-child(2) {
                transition-delay: 0.3s;
              }

              :nth-child(3) {
                transition-delay: 0.6s;
              }

              :nth-child(4) {
                transition-delay: 0.9s;
              }
            }
          }

          .content {
            scale: 1;
            transition-delay: 0.6s;
          }
        `;
      }

      return css``;
    }}
  }
`;
