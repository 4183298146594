import React, { useEffect, useRef, useState } from 'react';
import SliderSlick from 'react-slick';
import { FiMapPin } from 'react-icons/fi';
import { TbArrowUpRight } from 'react-icons/tb';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import { AiFillClockCircle } from 'react-icons/ai';

import { useLocation } from 'react-router-dom';
import {
  Container,
  Hero,
  Services,
  OurClinic,
  SpeedConsultation,
  SpecialTreatments,
  Benefits,
  Location,
  CoiKids,
  Testimonials,
  Avatar,
  FamilyCheckUp,
} from './styles';

import logo from '~/assets/logos/full-logo.svg';
import front from '~/assets/defaults/front.png';
import frontMobile from '~/assets/defaults/front-mobile.png';
import xRay from '~/assets/defaults/x-ray.png';
import sterilizationCenter from '~/assets/defaults/sterilization-center.png';
import technologicalTreatments from '~/assets/defaults/technological-treatments.png';
import personalizedTreatments from '~/assets/defaults/personalized-treatments.png';
import coiExpirience from '~/assets/defaults/coi-expirience.png';
import coiExpirienceLaptop from '~/assets/defaults/coi-expirience-laptop.png';
import coiExpirienceMobile from '~/assets/defaults/coi-expirience-mobile.png';
import checkUp from '~/assets/defaults/check-up.png';
import checkUpLaptop from '~/assets/defaults/check-up-laptop.png';
import checkUpMobile from '~/assets/defaults/check-up-mobile.png';
import stamp from '~/assets/defaults/stamp.png';
import dayclinic from '~/assets/icons/dayclinic.svg';
import preventiveTreatments from '~/assets/icons/preventive-treatments.svg';
import invisalign from '~/assets/icons/invisalign.svg';
import pediatricDentistry from '~/assets/icons/pediatric-dentistry.svg';
import endodontic from '~/assets/icons/endodontic.svg';
import orofacialHarmonization from '~/assets/icons/orofacial-harmonization.svg';
import oralAesthetics from '~/assets/icons/oral-aesthetics.svg';
import implantsDental from '~/assets/icons/implants-dental.svg';
import periodontics from '~/assets/icons/periodontics.svg';
import prosthesis from '~/assets/icons/prosthesis.svg';
import dentistry from '~/assets/icons/dentistry.svg';
import checkUpIcon from '~/assets/icons/check-up.svg';
import benefits from '~/assets/defaults/benefits.png';
import locationBig from '~/assets/defaults/location-big.png';
import locationMobile from '~/assets/defaults/location.png';
import kids from '~/assets/defaults/kids.svg';
import Logo from '~/assets/logos/Logo';
import kidsWaitingRoom from '~/assets/defaults/kids-waiting-room.png';
import kidsWaitingRoomMobile from '~/assets/defaults/kids-waiting-room-mobile.png';
import drawTable from '~/assets/defaults/draw-table.png';
import drawTableMobile from '~/assets/defaults/draw-table-mobile.png';
import kidsDentistConsutory from '~/assets/defaults/kids-dentist-consutory.png';
import kidsDentistConsutoryMobile from '~/assets/defaults/kids-dentist-consutory-mobile.png';
import avatar1 from '~/assets/defaults/avatar-1.png';
import avatar2 from '~/assets/defaults/avatar-2.png';
import avatar3 from '~/assets/defaults/avatar-3.png';
import avatar4 from '~/assets/defaults/avatar-4.png';
import family from '~/assets/defaults/family.png';
import familyMobile from '~/assets/defaults/family-mobile.png';
import familyTablet from '~/assets/defaults/family-tablet.png';

interface ITestimony {
  id: number;
  avatar: string;
  name: string;
  content: string;
}

const Welcome: React.FC = () => {
  const location = useLocation();
  const kidsSlideRef = useRef<SliderSlick>(null);
  const testmoniesSlideRef = useRef<SliderSlick>(null);
  const [testimonies] = useState<ITestimony[]>([
    {
      id: 1,
      avatar: avatar1,
      name: 'Ivonete',
      content:
        'A felicidade é o sorriso que está bem embaixo do nosso nariz!, valeu cada centavo. E vc é a responsável por esta felicidade! Vc é incrível!',
    },
    {
      id: 2,
      avatar: avatar2,
      name: 'Tamires',
      content:
        'Não tenho palavras para agradecer a você Dra Ivani, uma pessoa que dedica seu tempo e vida ao próximo, isso é um dom extraordinário.',
    },
    {
      id: 3,
      avatar: avatar3,
      name: 'Daniela',
      content:
        'Com o tratamento, ganhei a satisfação de poder distribuir sem economia um sorriso lindo e harmonioso. Sinceramente. ando rindo atoa.',
    },
    {
      id: 4,
      avatar: avatar4,
      name: 'Gabriela',
      content:
        'Sempre fico impressionada com estrutura da COI, tecnologia de ponta, os melhores cuidados, super atenciosos, tanto que indiquei minha família.',
    },
  ]);
  const [opened, setOpened] = useState(false);
  const [whatsappMessage, setWhatsappMessage] = useState('');

  useEffect(() => {
    if (location.pathname.includes('rede-social')) {
      setWhatsappMessage(
        'Olá! Encontrei vocês nas redes sociais e estou interessado em marcar um horário.'
      );
    } else {
      setWhatsappMessage('Olá gostaria de agendar uma consulta');
    }
  }, [location.pathname]);

  return (
    <Container>
      <Hero>
        <div className="container h-100">
          <div className="row align-items-lg-center h-100">
            <div className="col-lg-7 order-1 order-lg-0 text-center text-lg-start">
              <h1 className="text-white fw-light d-flex flex-column h2 display-lg-4 display-xxl-3 text-center text-lg-start">
                Referência em
                <span className="fw-semibold">Odontologia Prime</span> em
                Barueri
              </h1>
              <p className="h5 h3-lg text-white text-center text-lg-start mt-5 mt-lg-0">
                Transforme seu sorriso com o conforto e{' '}
                <br className="d-none d-lg-block" />
                tecnologia exclusivas da Clínica COI Prime.
              </p>
              <a
                href={`https://api.whatsapp.com/send?phone=5511967746140&text=${whatsappMessage}`}
                rel="noopener noreferrer"
                target="_blank"
                className="mt-5 btn btn-schedule rounded-pill text-white fs-5 fs-lg-4 fw-medium px-5 py-3"
                title="Agende já sua consulta!"
              >
                Agende já sua consulta!
              </a>
            </div>
            <div className="col-lg-5 align-self-start d-flex justify-content-center justify-content-lg-end pt-5 order-0 order-lg-1">
              <img src={logo} alt="logo" />
            </div>
          </div>
        </div>
      </Hero>
      <Services>
        <div className="container">
          <div className="row py-5 px-3 px-lg-0">
            <div className="col-12 bg-gray px-3 py-5 px-lg-5">
              <div className="row justify-content-between align-items-center px-3 px-lg-5">
                <div className="col-7 col-lg-6 col-xl-5">
                  <h2 className="fs-2 fs-lg-1 fw-medium mb-0">
                    Nossos principais serviços
                  </h2>
                </div>
                <div className="col-5 col-lg-6 mt-n5 mt-lg-0">
                  <img
                    src={stamp}
                    alt="A clínica mais tecnológica de Barueri"
                    className="d-block ms-auto stamp mt-lg-0 mt-xl-n4 w-100 w-lg-50 w-xl-auto"
                  />
                </div>
                <div className="col-lg-10 mt-4 mt-xl-n4">
                  <p className="fw-light fs-lg-5 text-black">
                    Descubra como nossa clínica odontológica oferece um cuidado
                    abrangente e especializado para a saúde e estética do seu
                    sorriso. Conheça nossos serviços que vão além do
                    convencional, proporcionando o melhor atendimento em:
                  </p>
                </div>
              </div>
              <div
                className={`row mt-5 px-3 px-lg-5 trataments ${
                  opened ? 'opened' : ''
                }`}
              >
                <div className="col-md-6 col-lg-3">
                  <img
                    src={dayclinic}
                    alt="Icone representando COI Experience Dayclinic"
                  />
                  <h3 className="mb-0 fs-5 fw-medium mt-4 text-black">
                    COI Experience <br />
                    Dayclinic
                  </h3>
                </div>
                <div className="col-md-6 col-lg-3 mt-5 mt-md-0">
                  <img
                    src={preventiveTreatments}
                    alt="Icone representando Tratamentos preventivos"
                  />
                  <h3 className="mb-0 fs-5 fw-medium mt-4 text-black">
                    Tratamentos <br />
                    preventivos
                  </h3>
                </div>
                <div className="col-md-6 col-lg-3 mt-5 mt-lg-0">
                  <img
                    src={invisalign}
                    alt="Icone representando Aparelhos Transparentes"
                  />
                  <h3 className="mb-0 fs-5 fw-medium mt-4 text-black">
                    Aparelhos <br />
                    Transparentes
                  </h3>
                </div>
                <div className="col-md-6 col-lg-3 mt-5 mt-lg-0">
                  <img
                    src={pediatricDentistry}
                    alt="Icone representando Tratamentos Odontopediatricos"
                  />
                  <h3 className="mb-0 fs-5 fw-medium mt-4 text-black">
                    Tratamentos <br />
                    Odontopediatricos
                  </h3>
                </div>
                <div className="col-md-6 col-lg-3 mt-5">
                  <img
                    src={endodontic}
                    alt="Icone representando Tratamentos Odontopediatricos"
                  />
                  <h3 className="mb-0 fs-5 fw-medium mt-4 text-black">
                    Endodontia
                  </h3>
                </div>
                <div className="col-md-6 col-lg-3 mt-5">
                  <img
                    src={orofacialHarmonization}
                    alt="Icone representando Tratamentos Odontopediatricos"
                  />
                  <h3 className="mb-0 fs-5 fw-medium mt-4 text-black">
                    Harmonização <br />
                    Orofacial
                  </h3>
                </div>
                <div className="col-md-6 col-lg-3 mt-5">
                  <img
                    src={oralAesthetics}
                    alt="Icone representando Tratamentos Odontopediatricos"
                  />
                  <h3 className="mb-0 fs-5 fw-medium mt-4 text-black">
                    Estética <br />
                    Bucal
                  </h3>
                </div>
                <div className="col-md-6 col-lg-3 mt-5">
                  <img
                    src={implantsDental}
                    alt="Icone representando Tratamentos Odontopediatricos"
                  />
                  <h3 className="mb-0 fs-5 fw-medium mt-4 text-black">
                    Implantes <br />
                    dentários
                  </h3>
                </div>
                <div className="col-md-6 col-lg-3 mt-5">
                  <img
                    src={periodontics}
                    alt="Icone representando Tratamentos Odontopediatricos"
                  />
                  <h3 className="mb-0 fs-5 fw-medium mt-4 text-black">
                    Periodontia
                  </h3>
                </div>
                <div className="col-md-6 col-lg-3 mt-5">
                  <img
                    src={prosthesis}
                    alt="Icone representando Tratamentos Odontopediatricos"
                  />
                  <h3 className="mb-0 fs-5 fw-medium mt-4 text-black">
                    Prótese
                  </h3>
                </div>
                <div className="col-md-6 col-lg-3 mt-5">
                  <img
                    src={dentistry}
                    alt="Icone representando Tratamentos Odontopediatricos"
                  />
                  <h3 className="mb-0 fs-5 fw-medium mt-4 text-black">
                    Odontia
                  </h3>
                </div>
                <div className="col-md-6 col-lg-3 mt-5">
                  <img
                    src={checkUpIcon}
                    alt="Icone representando Tratamentos Odontopediatricos"
                  />
                  <h3 className="mb-0 fs-5 fw-medium mt-4 text-black">
                    Check-up <br />
                    Preventivo
                  </h3>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-4">
                  <button
                    type="button"
                    className="fs-5 fs-lg-4 fw-medium text-center w-100 d-block mt-5 mb-4 text-black border-0 bg-transparent"
                    title="Ver todos os serviços"
                    onClick={() => setOpened((state) => !state)}
                  >
                    {opened ? 'Ver menos' : 'Ver todos os serviços'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Services>
      <OurClinic>
        <div className="container py-5 my-5">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6 col-xl-4">
              <div>
                <h2 className="h2 display-lg-4 fw-medium mb-5 text-black">
                  Conheça <br />
                  nossa clínica
                </h2>
                <div className="d-block d-lg-none position-relative mb-5">
                  <img
                    src={front}
                    alt="A faixada da clínica a noite com uma luz azul e o letreiro onde está escrito COI Prime Odontologia aceso com uma luz branca"
                    className="w-100 front-mobile front-sm d-none d-sm-block"
                  />
                  <img
                    src={frontMobile}
                    alt="A faixada da clínica a noite com uma luz azul e o letreiro onde está escrito COI Prime Odontologia aceso com uma luz branca"
                    className="w-100 front-mobile d-block d-sm-none"
                  />
                  <div className="d-flex box-group">
                    <div className="brown-box p-3 p-lg-4 me-4">
                      <p className="mb-0 small normal-lg">Pacientes</p>
                      <p className="fs-5 fs-lg-3 mb-0 my-lg-2">
                        <b>+ 8 mil</b>
                      </p>
                      <p className="mb-0 small normal-lg">Atendidos</p>
                    </div>
                    <div className="black-box p-3 p-lg-4">
                      <p className="mb-0 small normal-lg">Doutores</p>
                      <p className="fs-5 fs-lg-3 mb-0 my-lg-2">
                        <b>09</b>
                      </p>
                      <p className="mb-0 small normal-lg">
                        Doutores especializados
                      </p>
                    </div>
                  </div>
                </div>
                <p className="content">
                  Uma estrutura diferenciada, com 650m² dedicados à Odontologia
                  Prime em todas as&nbsp;especialidades da odontologia
                </p>
                <p className="content">
                  Com 8 consultórios, 4 salas de esperas, espaço Kids, elevador
                  com acesso em toda clínica, plataforma para acessibilidade e
                  estacionamento no subsolo.
                </p>
                <div className="location rounded-pill px-4 py-2 d-flex align-items-center justify-content-center">
                  <FiMapPin size={30} color="#BE7E38" className="me-2" />
                  <p className="mb-0 fw-medium">Localizada no Bethaville</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-8 d-none d-lg-block">
              <div className="position-relative">
                <img
                  src={front}
                  alt="A faixada da clínica a noite com uma luz azul e o letreiro onde está escrito COI Prime Odontologia aceso com uma luz branca"
                  className="d-block ms-lg-auto w-100 w-xl-auto"
                />
                <div className="d-flex box-group">
                  <div className="brown-box p-3 p-lg-4 me-4">
                    <p className="mb-0 small normal-lg">Pacientes</p>
                    <p className="fs-5 fs-lg-3 mb-0 my-lg-2">
                      <b>+ 8 mil</b>
                    </p>
                    <p className="mb-0 small normal-lg">Atendidos</p>
                  </div>
                  <div className="black-box p-3 p-lg-4">
                    <p className="mb-0 small normal-lg">Doutores</p>
                    <p className="fs-5 fs-lg-3 mb-0 my-lg-2">
                      <b>09</b>
                    </p>
                    <p className="mb-0 small normal-lg">
                      Doutores especializados
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OurClinic>
      <SpeedConsultation>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="bg-gray">
                <p>Agilidade em sua Consulta</p>
                <h2 className="h5 h3-lg">
                  Atendimento Prime com <b>alta tecnologia</b> para{' '}
                  <br className="d-none d-lg-block" />
                  cuidar da saúde dos seus dentes com qualidade
                </h2>
                <p className="fw-light mb-4">
                  Nossos especialistas em odontologia estão comprometidos em
                  proporcionar a você a melhor{' '}
                  <br className="d-none d-lg-block" />
                  experiência de cuidado bucal, com serviços personalizados e
                  avançada tecnologia.
                </p>
                <p className="fw-light mb-0">
                  Descubra por que somos a escolha número um para transformar
                  sorrisos na região!
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3 d-flex flex-column align-items-center techs">
              <img src={xRay} alt="Raio X panorâmico" />
              <p className="mb-5 mb-lg-0 mt-3 mt-lg-4 text-center">
                Raio X <br />
                panorâmico
              </p>
            </div>
            <div className="col-6 col-md-3 d-flex flex-column align-items-center techs">
              <img src={sterilizationCenter} alt="Centro de esterilização" />
              <p className="mb-5 mb-lg-0 mt-3 mt-lg-4 text-center">
                Centro de <br />
                esterilização
              </p>
            </div>
            <div className="col-6 col-md-3 d-flex flex-column align-items-center techs">
              <img
                src={technologicalTreatments}
                alt="Tratamentos Tecnologicos"
              />
              <p className="mb-0 mt-3 mt-lg-4 text-center">
                Tratamentos <br />
                Tecnologicos
              </p>
            </div>
            <div className="col-6 col-md-3 d-flex flex-column align-items-center techs">
              <img
                src={personalizedTreatments}
                alt="Tratamentos personalizados"
              />
              <p className="mb-0 mt-3 mt-lg-4 text-center">
                Tratamentos <br />
                personalizados
              </p>
            </div>
          </div>
        </div>
      </SpeedConsultation>
      <Benefits>
        <div className="container py-5 my-5">
          <div className="row align-items-center">
            <div className="col-lg-6 order-1 order-lg-0">
              <h2 className="h2 h1-lg mb-5 text-black">
                Desfrute dos Benefícios Exclusivos na COI Prime
              </h2>
              <div className="w-xl-75">
                <p className="fs-5 mb-4">
                  Concretize a visão de ter acesso ao que há de melhor em
                  odontologia, com tecnologia de ponta, proporcionando
                  tratamentos confortáveis e eficazes.{' '}
                </p>
                <p className="fs-5 mb-5">
                  Na COI Prime, priorizamos sua saúde, garantindo uma
                  experiência excepcional em cada cuidado que oferecemos.
                </p>
              </div>
              <a
                href={`https://api.whatsapp.com/send?phone=5511967746140&text=${whatsappMessage}`}
                rel="noopener noreferrer"
                target="_blank"
                className="btn btn-primary rounded-pill py-3 fs-5 fw-medium w-100 w-lg-75 w-xl-50"
                title="Agende já sua consulta!"
              >
                Agende já sua consulta!
              </a>
            </div>
            <div className="col-lg-6 order-0 order-lg-1 mb-5 mb-lg-0">
              <img
                src={benefits}
                alt="Um consutório com tecnologia de ponta, mostrando uma TV e uma cadeira de dentista branca"
                className="w-100 w-md-75 w-lg-100 d-block mx-md-auto mx-lg-0"
              />
            </div>
          </div>
        </div>
      </Benefits>
      <Location>
        <div className="container py-5 my-5">
          <div className="row">
            <div className="col-12">
              <div className="bg-gray-mobile">
                <div className="row justify-content-between align-items-center align-items-xxl-end">
                  <div className="col-lg-5 mb-5 mb-lg-0 pe-lg-0 position-relative">
                    <img
                      src={locationBig}
                      alt="A faixada da clínica a noite com uma luz azul e o letreiro onde está escrito COI Prime Odontologia aceso com uma luz branca"
                      className="w-100 w-md-75 w-lg-100 d-block d-lg-none d-xl-block mx-md-auto mx-lg-0"
                    />
                    <img
                      src={locationMobile}
                      alt="A faixada da clínica a noite com uma luz azul e o letreiro onde está escrito COI Prime Odontologia aceso com uma luz branca"
                      className="w-100 w-md-75 w-lg-100 d-none d-lg-block d-xl-none mx-md-auto mx-lg-0"
                    />
                    <div className="detail" />
                  </div>
                  <div className="col-lg-7 bg-gray">
                    <div>
                      <h3 className="h6 h5-lg fw-light text-black">
                        LOCALIZAÇÃO
                      </h3>
                      <h2 className="h2 h1-lg mb-5 text-black">
                        Conheça <br className="d-block d-lg-none" />
                        nossa clínica
                      </h2>
                    </div>
                    <div className="d-flex align-items-lg-center mb-4">
                      <FiMapPin size={30} color="#BE7E38" />
                      <div className="ms-2">
                        <h3 className="fs-6 fw-normal mb-0">Endereço:</h3>
                        <p className="mb-0">
                          Av. Trindade, 165 – Bethaville I, Barueri – SP,
                          06404-326
                        </p>
                      </div>
                    </div>
                    <ul className="ps-lg-5 ms-lg-3 mb-5">
                      <li className="text-secondary">
                        <p className="mb-0">
                          Fácil acesso pela Rodovia Castelo Branco
                        </p>
                      </li>
                      <li className="text-secondary">
                        <p className="mb-0">Elevador de acessos</p>
                      </li>
                      <li className="text-secondary">
                        <p className="mb-0">Estacionamento própio</p>
                      </li>
                    </ul>
                    <a
                      href="https://maps.app.goo.gl/LPv5rTrzvAVKhsVSA"
                      rel="noopener noreferrer"
                      target="_blank"
                      className="btn btn-black rounded-pill px-4 py-2 fw-medium ms-2 ms-lg-0"
                      title="Ver rotas"
                    >
                      Ver rotas{' '}
                      <TbArrowUpRight size={24} color="#fff" className="ms-2" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Location>
      <CoiKids>
        <div className="bg-gray">
          <div className="container pb-5">
            <div className="row">
              <div className="col-lg-12 d-flex flex-column">
                <div className="align-self-center mt-n5">
                  <div className="d-flex align-items-end logo mb-4">
                    <Logo color="#146392" className="me-2" />
                    <span className="text-start d-block fs-5 fw-light mb-0 logo-name">
                      Prime Odontologia
                    </span>
                  </div>
                  <img src={kids} alt="KIDS" className="kids" />
                </div>
                <div className="my-5">
                  <SliderSlick
                    ref={kidsSlideRef}
                    autoplay
                    autoplaySpeed={5000}
                    infinite
                  >
                    <div className="px-3">
                      <img
                        src={kidsWaitingRoom}
                        alt="Doutora Adrielly chamando a paciete criança para entrar no consultório, enquano outros pacientes e seus pais aguardam na sala de espera infantil"
                        className="w-100 d-none d-lg-block"
                      />
                      <img
                        src={kidsWaitingRoomMobile}
                        alt="Doutora Adrielly chamando a paciete criança para entrar no consultório, enquano outros pacientes e seus pais aguardam na sala de espera infantil"
                        className="w-100 d-block d-lg-none"
                      />
                    </div>
                    <div className="px-3">
                      <img
                        src={drawTable}
                        alt="Doutora Adrielly chamando a paciete criança para entrar no consultório, enquano outros pacientes e seus pais aguardam na sala de espera infantil"
                        className="w-100 d-none d-lg-block"
                      />
                      <img
                        src={drawTableMobile}
                        alt="Doutora Adrielly chamando a paciete criança para entrar no consultório, enquano outros pacientes e seus pais aguardam na sala de espera infantil"
                        className="w-100 d-block d-lg-none"
                      />
                    </div>
                    <div className="px-3">
                      <img
                        src={kidsDentistConsutory}
                        alt="Doutora Adrielly chamando a paciete criança para entrar no consultório, enquano outros pacientes e seus pais aguardam na sala de espera infantil"
                        className="w-100 d-none d-lg-block"
                      />
                      <img
                        src={kidsDentistConsutoryMobile}
                        alt="Doutora Adrielly chamando a paciete criança para entrar no consultório, enquano outros pacientes e seus pais aguardam na sala de espera infantil"
                        className="w-100 d-block d-lg-none"
                      />
                    </div>
                  </SliderSlick>
                  <div className="d-flex justify-content-end mt-3 px-5">
                    <button
                      type="button"
                      className="p-2 border-0 bg-transparent me-1"
                      onClick={() => {
                        kidsSlideRef.current?.slickPrev();
                      }}
                      title="Anterior"
                    >
                      <SlArrowLeft size={20} color="#000" />
                    </button>
                    <button
                      type="button"
                      className="p-2 border-0 bg-transparent ms-1"
                      onClick={() => {
                        kidsSlideRef.current?.slickNext();
                      }}
                      title="Próximo"
                    >
                      <SlArrowRight size={20} color="#000" />
                    </button>
                  </div>
                </div>
                <div className="px-3">
                  <p className="fs-5 fw-light mb-4">
                    Na COI Prime Kids, cada sorriso é uma aventura! Nossa ala
                    exclusiva oferece um ambiente lúdico e acolhedor, criado
                    especialmente para os&nbsp;pequenos.
                  </p>
                  <p className="fs-5 fw-light mb-4">
                    Com profissionais dedicados e tratamentos personalizados,
                    garantimos que a visita ao dentista seja repleta de diversão
                    e&nbsp;cuidado.
                  </p>
                  <p className="fs-5 fw-light mb-5">
                    O cuidado preventivo começa na&nbsp;infância
                  </p>
                </div>
                <a
                  href={`https://api.whatsapp.com/send?phone=5511967746140&text=${whatsappMessage}`}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="btn btn-orange px-5 py-3 rounded-pill fs-5 fw-medium align-self-start w-100 w-lg-auto"
                  title="Agendar consulta"
                >
                  Agendar consulta
                </a>
              </div>
            </div>
          </div>
        </div>
      </CoiKids>
      <Testimonials>
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <h2 className="h6 h5-lg fw-light mb-5">
                DEPOIMENTOS <br />
                <span className="h2 h1-lg fw-medium">Clientes COI</span>
              </h2>
              <div>
                <SliderSlick
                  ref={testmoniesSlideRef}
                  slidesToShow={3}
                  autoplay
                  autoplaySpeed={10000}
                  infinite
                  arrows={false}
                  responsive={[
                    {
                      breakpoint: 991,
                      settings: {
                        slidesToShow: 2,
                      },
                    },
                    {
                      breakpoint: 767,
                      settings: {
                        slidesToShow: 1,
                      },
                    },
                  ]}
                >
                  {testimonies.map((testimony) => (
                    <div key={testimony.id} className="p-3">
                      <div className="testimony p-4">
                        <div className="d-flex align-items-center mb-4">
                          <Avatar src={testimony.avatar} className="me-2" />
                          <p className="mb-0">{testimony.name}</p>
                        </div>
                        <p>{testimony.content}</p>
                      </div>
                    </div>
                  ))}
                </SliderSlick>
                <div className="d-flex justify-content-end mt-3 px-5">
                  <button
                    type="button"
                    className="p-2 border-0 btn-testmony-slide me-1"
                    onClick={() => {
                      testmoniesSlideRef.current?.slickPrev();
                    }}
                    title="Anterior"
                  >
                    <SlArrowLeft size={20} color="#000" />
                  </button>
                  <button
                    type="button"
                    className="p-2 border-0 btn-testmony-slide ms-1"
                    onClick={() => {
                      testmoniesSlideRef.current?.slickNext();
                    }}
                    title="Próximo"
                  >
                    <SlArrowRight size={20} color="#000" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Testimonials>
      <FamilyCheckUp>
        <div className="container py-5">
          <div className="row px-4">
            <div className="col-12 bg-brown px-3 py-4 py-lg-5 px-md-5 overflow-hidden">
              <div className="row justify-content-between">
                <div className="col-lg-8 d-flex flex-column justify-content-between">
                  <h2 className="h2 h1-lg text-white w-lg-75 mt-5">
                    Aproveite o recesso para cuidar do que mais importa, sua
                    família
                  </h2>
                  <div className="d-flex d-lg-none position-relative my-4 my-md-5">
                    <p className="tag px-4 py-2 text-white rounded-pill mb-0">
                      <AiFillClockCircle
                        size={32}
                        color="#fff"
                        className="me-2"
                      />{' '}
                      Somente no mês de julho
                    </p>
                    <img
                      src={familyMobile}
                      alt="Uma familia sorrindo felizez e satifeitos em um campo florido."
                      className="w-100 d-md-none"
                    />
                    <img
                      src={familyTablet}
                      alt="Uma familia sorrindo felizez e satifeitos em um campo florido."
                      className="w-100 d-none d-md-block"
                    />
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <p className="text-white fw-light">
                        Em <b className="fw-semibold">julho</b>, proporcionamos
                        a você a oportunidade de cuidar da saúde bucal de toda a
                        família. Traga seus filhos, cônjuge, pais e mães para o
                        nosso check-up preventivo familiar com qualidade Prime
                        que oferecemos
                      </p>
                    </div>
                    <div className="col-lg-6 py-4 py-lg-0">
                      <p className="text-white fw-light">
                        Aqui na COI Prime, estamos comprometidos em garantir
                        sorrisos saudáveis para toda a família. Agende agora e
                        comece o ano com o pé direito na saúde bucal!
                      </p>
                    </div>
                  </div>
                  <a
                    href={`https://api.whatsapp.com/send?phone=5511967746140&text=${whatsappMessage}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="mb-5 btn btn-primary rounded-pill text-white fs-5 fs-lg-4 fw-medium px-4 px-lg-5 py-3 align-self-start"
                    title="Agende já sua consulta!"
                  >
                    Agende já sua consulta!
                  </a>
                </div>
                <div className="col-lg-4 d-flex justify-content-end position-relative d-none d-lg-block">
                  <p className="tag px-4 py-2 text-white rounded-pill mb-0">
                    <AiFillClockCircle
                      size={32}
                      color="#fff"
                      className="me-2"
                    />{' '}
                    Somente no mês de julho
                  </p>
                  <img
                    src={family}
                    alt="Uma familia sorrindo felizez e satifeitos em um campo florido."
                    className="w-lg-auto w-xl-100 w-xxl-auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </FamilyCheckUp>
      <SpecialTreatments>
        <div className="container py-5">
          <SliderSlick
            infinite
            arrows={false}
            centerPadding="0px"
            autoplay
            autoplaySpeed={40000}
            dots
            adaptiveHeight
          >
            <div className="px-3">
              <div className="row bg-gray my-5">
                <div className="col-lg-5 px-0">
                  <img
                    src={coiExpirience}
                    alt="Cinco doutores reunidos, analisando minuciosamente um raio X panorâmico e discutindo estratégias de tratamento para o paciente."
                    className="w-100 d-none d-xl-block"
                  />
                  <img
                    src={coiExpirienceLaptop}
                    alt="Cinco doutores reunidos, analisando minuciosamente um raio X panorâmico e discutindo estratégias de tratamento para o paciente."
                    className="w-100 d-none d-lg-block d-xl-none"
                  />
                  <img
                    src={coiExpirienceMobile}
                    alt="Cinco doutores reunidos, analisando minuciosamente um raio X panorâmico e discutindo estratégias de tratamento para o paciente."
                    className="w-100 d-lg-none"
                  />
                </div>
                <div className="col-lg-7 px-3 py-5 px-lg-5 d-flex flex-column justify-content-between">
                  <div>
                    <h2 className="fs-4 fs-lg-1 fw-medium text-white">
                      COI Experience
                    </h2>
                    <p className="text-gray">
                      Para quem não tem tempo mas precisa se cuidar
                    </p>
                  </div>
                  <div className="pe-lg-5 mt-4 mt-lg-0">
                    <p className="text-white mb-4">
                      Cada pessoa é única. E assim deve ser a avaliação da sua
                      saúde bucal.
                    </p>
                    <p className="text-white mb-4">
                      Na COI Experience, otimizamos seu tempo com procedimentos
                      ágeis e especializados, proporcionando resultados eficazes
                      em um único dia.
                    </p>
                    <p className="text-white mb-4">
                      Confie em uma equipe com vasta experiência. Nossos
                      profissionais, com mais de 20 anos de prática, estão
                      comprometidos em oferecer tratamentos seguros e
                      personalizados.
                    </p>
                    <p className="text-white">
                      A COI Experience é um Dayclinic completo e personalizado
                      para quem quer o máximo de precisão, em linha com as
                      melhores práticas.
                    </p>
                  </div>
                  <a
                    href={`https://api.whatsapp.com/send?phone=5511967746140&text=${whatsappMessage}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="btn btn-primary rounded-pill py-3 fs-lg-5 fw-medium w-100 w-lg-75 w-xl-50 mt-4 mt-xl-0"
                    title="Agende já sua consulta!"
                  >
                    Agende já sua consulta!
                  </a>
                </div>
              </div>
            </div>
            <div className="px-3">
              <div className="row bg-gray my-5">
                <div className="col-lg-5 px-0">
                  <img
                    src={checkUp}
                    alt="Doutora Ivani usando uma camera intra oral no paciente mostrando a ele em tempo real como estão seus dentes e falando o que se deve ou não fazer."
                    className="w-100 d-none d-xl-block"
                  />
                  <img
                    src={checkUpLaptop}
                    alt="Doutora Ivani usando uma camera intra oral no paciente mostrando a ele em tempo real como estão seus dentes e falando o que se deve ou não fazer."
                    className="w-100 d-none d-lg-block d-xl-none"
                  />
                  <img
                    src={checkUpMobile}
                    alt="Doutora Ivani usando uma camera intra oral no paciente mostrando a ele em tempo real como estão seus dentes e falando o que se deve ou não fazer."
                    className="w-100 d-lg-none"
                  />
                </div>
                <div className="col-lg-7 px-3 py-5 px-lg-5 d-flex flex-column justify-content-between">
                  <h2 className="fs-4 fs-lg-1 fw-medium text-white">
                    Check-up Digital
                  </h2>
                  <div className="pe-lg-5 mt-4 mt-xl-0">
                    <p className="text-white mb-4 mt-n3">
                      Descubra Antes, Cuide Sempre:{' '}
                      <b className="d-lg-block d-xl-inline">
                        Check-up Digital COI Prime
                      </b>
                    </p>
                    <p className="text-white mb-4">
                      Você sabia que problemas bucais podem estar se
                      desenvolvendo <br className="d-none d-xl-block" />
                      sem que você perceba?
                    </p>
                    <div className="d-flex align-items-center">
                      <p className="text-white mb-0 tooth-content">
                        Com o Check-up Digital COI Prime, identificamos questões
                        em estágio inicial, prevenindo complicações futuras
                      </p>
                    </div>
                  </div>
                  <a
                    href={`https://api.whatsapp.com/send?phone=5511967746140&text=${whatsappMessage}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="btn btn-primary rounded-pill py-3 fs-lg-5 fw-medium w-100 w-lg-75 w-xl-50 mt-4 mt-xl-0"
                    title="Agende já sua consulta!"
                  >
                    Agende já sua consulta!
                  </a>
                </div>
              </div>
            </div>
          </SliderSlick>
        </div>
      </SpecialTreatments>
    </Container>
  );
};

export default Welcome;
