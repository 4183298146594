import React, { useCallback, useEffect, useState } from 'react';

import { Container, Slide1, Slide2, Slide3, Slide4, Slide5 } from './styles';

import fullLogo from '~/assets/tv/white-full-logo.png';
import shortLogo from '~/assets/tv/white-short-logo.svg';
import checkUp1 from '~/assets/tv/check-up-1.png';
import checkUp2 from '~/assets/tv/check-up-2.png';
import checkUp3 from '~/assets/tv/check-up-3.png';
import checkUp4 from '~/assets/tv/check-up-4.png';
import text from '~/assets/tv/text.png';

interface IPreventiveCheckUp {
  show: boolean;
  startAnim: boolean;
}

const PreventiveCheckUp: React.FC<IPreventiveCheckUp> = ({
  show,
  startAnim,
}) => {
  const [step, setStep] = useState(0);

  const changeSlide = useCallback((stepSelected) => {
    setTimeout(() => {
      setStep(stepSelected);
      if (stepSelected + 1 <= 5) {
        changeSlide(stepSelected + 1);
      } else {
        setTimeout(() => {
          setStep(0);
        }, 10000);
      }
    }, 10000);
  }, []);

  useEffect(() => {
    if (startAnim) {
      setStep(1);
      setTimeout(() => {
        changeSlide(2);
      }, 300);
    }
  }, [changeSlide, startAnim]);

  useEffect(() => {
    if (startAnim) {
      setStep(1);
      setTimeout(() => {
        changeSlide(2);
      }, 300);
    }
  }, [changeSlide, startAnim]);

  return (
    <Container show={show} startAnim={startAnim}>
      <Slide1 show={step === 1} startAnim={step === 1}>
        <div className="slide-container">
          <img src={checkUp1} alt="check-up" className="photo" />
          <img src={fullLogo} alt="fullLogo" className="logo" />
          <p>
            A Importância do <b>Check-up Preventivo</b> para&nbsp;você!
          </p>
        </div>
      </Slide1>
      <Slide2
        show={step === 2}
        startAnim={step === 2}
        notShow={step !== 0 && step !== 1 && step !== 5}
      >
        <div className="slide-container">
          <p className="first-p">Em apenas uma consulta, você garante...</p>
          <h1>Diagnóstico completo</h1>
          <p className="last-p">
            Exames realizados aqui na{' '}
            <span className="rounded-pill px-2">clínica</span>
          </p>
          <img src={shortLogo} alt="short-logo" className="logo" />
          <img src={checkUp2} alt="photo" className="photo" />
        </div>
      </Slide2>
      <Slide3 show={step === 3} startAnim={step === 3}>
        <div className="slide-container">
          <h1>Prevenção eficaz</h1>
          <p className="last-p">
            Identificação e tratamento de problemas bucais em seu início
          </p>
          <img src={checkUp3} alt="photo" className="photo" />
        </div>
      </Slide3>
      <Slide4 show={step === 4} startAnim={step === 4}>
        <div className="slide-container">
          <h1>Sorriso e confiança renovada</h1>
          <p className="last-p">
            Limpeza profissional profunda e acompanhamento contínuo para um
            sorriso
          </p>
          <img src={checkUp4} alt="photo" className="photo" />
        </div>
      </Slide4>
      <Slide5 show={step === 5} startAnim={step === 5}>
        <div className="slide-container">
          <img
            src={text}
            alt="O Check-up é mais do que um check-up, é uma experiência completa"
            className="text"
          />
          <img src={fullLogo} alt="short-logo" className="logo" />
        </div>
      </Slide5>
    </Container>
  );
};

export default PreventiveCheckUp;
