import React, { useEffect, useState } from 'react';

import { Container } from './styles';

import topDetail from '~/assets/tv/top-detail.svg';
import bottomDetail from '~/assets/tv/bottom-detail.svg';
import coiKidsOutline from '~/assets/tv/coi-kids-outline.svg';
import coiFill from '~/assets/tv/coi-fill.svg';
import kidsFill from '~/assets/tv/kids-fill.svg';
import plus from '~/assets/tv/plus.svg';
import invisalign from '~/assets/tv/invisalign.png';
import kid1 from '~/assets/tv/kid-01.png';
import kid2 from '~/assets/tv/kid-02.png';
import kid3 from '~/assets/tv/kid-03.png';
import kid4 from '~/assets/tv/kid-04.png';

interface ISlideCoiKidsInvisalign {
  show: boolean;
  startAnim: boolean;
}

const SlideCoiKidsInvisalign: React.FC<ISlideCoiKidsInvisalign> = ({
  show,
  startAnim,
}) => {
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (startAnim) {
      setStep(1);
      setTimeout(() => {
        setStep(2);

        setTimeout(() => {
          setStep(3);
        }, 3000);
      }, 3000);
    }
  }, [startAnim]);

  return (
    <Container step={step} show={show} startAnim={startAnim}>
      <div className="slide-container position-relative">
        <img src={topDetail} alt="top-detail" className="top-detail" />
        <div className="logo-group">
          <div className="logo">
            <img
              src={coiKidsOutline}
              alt="coi-kids-outline"
              className="coi-kids-outline"
            />
            <img src={coiFill} alt="coi-fill" className="coi-fill" />
            <img src={kidsFill} alt="kids-fill" className="kids-fill" />
          </div>
          <div className="invisalign-group">
            <img src={plus} alt="plus" className="plus" />
            <img src={invisalign} alt="invisalign" className="invisalign" />
          </div>
        </div>
        <div className="kids">
          <img src={kid1} alt="kid1" />
          <img src={kid2} alt="kid2" />
          <img src={kid3} alt="kid3" />
          <img src={kid4} alt="kid4" />
        </div>
        <p className="content">
          <span className="fw-medium">Segurança</span> e{' '}
          <span className="fw-medium">qualidade</span> para nossos COI Kids
        </p>
        <img src={bottomDetail} alt="bottom-detail" className="bottom-detail" />
      </div>
    </Container>
  );
};

export default SlideCoiKidsInvisalign;
